<template>
  <div class="container-qr-code">
    <h2 class="h2-qr-code">Apresente esse Qr code a clínica no ato do atendimento.</h2>
    <qrcode-vue :value="value" :size="size" level="H" />
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'

export default {
  components: {
    QrcodeVue,
  },
  data() {
    return {
      scheduleId: null,
      value: null,
      size: 300,

    }
  },
  created() {
    this.value = `${process.env.VUE_APP_WEB}/#/kit-atendimento`
  }
}
</script>

<style>
.container-qr-code {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.h2-qr-code {
  margin: 30px auto !important;
  padding: 0 10px !important;
  text-align: center;
}
</style>
